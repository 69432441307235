import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { CoreProvider, FirebaseProvider, listingops } from '@listingops/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { initFirebase } from './firebase/app';
import { AppRouter } from './AppRouter';

const hostname = 'listingopportunities';

const App: React.FC = () => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <HelmetProvider>
      <ThemeProvider theme={listingops}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <FirebaseProvider hostname={hostname} initFirebase={initFirebase}>
            <CoreProvider>
              <AppRouter />
            </CoreProvider>
          </FirebaseProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
