import { getApps, initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  initializeAuth,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

import { prod } from '../env-constants';

export const initFirebase = () => {
  const apps = getApps();
  if (apps.length) return;

  const app = initializeApp({
    apiKey: 'AIzaSyDprzQcFwuKeZoJ01aJl4hBJMTCxGFHZeE',
    authDomain: 'listingops.firebaseapp.com',
    projectId: 'listingops',
    storageBucket: 'listingops.appspot.com',
    messagingSenderId: '43663801893',
    appId: '1:43663801893:web:d249fe10ea083fa0bd89cc',
  });

  initializeAuth(app, { persistence: browserSessionPersistence });

  if (!prod) {
    connectAuthEmulator(getAuth(), 'http://localhost:9099', {
      disableWarnings: true,
    });
    console.info('[Auth Emulator Connected]');

    connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
    console.info('[Firestore Emulator Connected]');
  }
};
