import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LoadingProgress } from '@listingops/react';

const Dashboard = lazy(() => import('./DashboardPage'));
const Layout = lazy(() => import('./Layout'));
const Login = lazy(() => import('./LoginPage'));
const Reset = lazy(() => import('./ResetPage'));
const NotFound = lazy(() => import('./NotFoundPage'));

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<LoadingProgress isLoading />}>
      <Routes>
        <Route element={<Layout links={[]} />}>
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>
        <Route path='/' element={<Login />} />
        <Route path='/reset' element={<Reset />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
